/** ============================================================================
 * @components Term of Access
 * ========================================================================== */

.termOfAccess{  
    .text-wrapper{
        margin-bottom: 5%;
    }
    
    p{
        margin: 0;
        margin-left: 3%;
    }
}