/** ============================================================================
 * @pages Home
 * ========================================================================== */

/***Main-Section***/
.home {
    h2 {
        font-size: 1.8rem;
        
        @include media-breakpoint-up(sm) {
            font-size: 2rem;
        }
        
        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }
}

.section1{
    background: url("/img/background/bg1.png") center center no-repeat;
    background-size: cover;
    position: relative;

    @include media-breakpoint-up(sm){
        .dropdown-sub{
            display: none;
        }
    }
    
    .component--rm1{
        display: none;
        @include media-breakpoint-down (sm){
            max-width: 30vw;
            position: absolute;
            top: 15vh;
            right: 5%;
            display: block;
        }
    }

    .col--text{
        position: relative;
    }

    .col--text h1::after{
        content: url('/img/components/component-bulat.svg');
        position: absolute;
        display: block;
        right: 0;
        top: -80%;

        @include media-breakpoint-down (md){
            top: -160%;
/*             width: 50px;
            height: auto; */
        }

        @include media-breakpoint-down(sm){
            display: none;
        }

        @include media-breakpoint-up (xxl){
            right: 25%;
        }
    }
    
    .row--content{
        position: relative;
        padding: 30vh 0 0;
        
        @include media-breakpoint-up(md) {
            padding-bottom: 2rem;
        }
        
        @include media-breakpoint-up(lg) {
            padding: 80px 0 0;
        }

        h1{
            font-size: 2rem;

            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 4rem;
                max-width: 550px;
            }
        }

        h3{
            font-size: 1.2rem;

            @include media-breakpoint-up(md) {
                font-size: 1.8rem;
            }
        }

        .btn{
            background-color: $primary-color;
            border-radius: 50px;
            color: $secondary-color;
        }
    }
    
    .content__heading{
        font-size: .9rem;
        font-weight: 500;
    }

    .col--text h1, h3{
        text-shadow: 2px 3px 20px #1a1919;
    }

    .col--inverse p{
        font-size: .7rem;
        margin-bottom: 0 !important;
    }

    .arrowBtn{
        position: absolute;
        border-radius: 50%;
        margin: 0;
        right: -2rem;
        bottom: 50% !important;
        display: none;
        transform: translateY(50%);
        cursor: pointer;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .transfer-content-wrapper{
        @include media-breakpoint-up(md) {
            padding-right: 30px;
        }
    }

    .transfer-content-wrapper.active {
        @include media-breakpoint-up(lg) {
            .transfer-content {
                transform: translateX(103%);
            }
        
            .arrowBtn{
                right: calc(100% - 2rem);
            }
        }

        @include media-breakpoint-up (xxl){
            .transfer-content {
                transform: translateX(117%);
            }
        }
    }

    .transfer-content{
        max-width: 460px;
        background: $secondary-color;
        border-radius: 2em;
        color: black;
        position: relative;
        padding: 20px;
        margin: auto;
        margin-left: auto;
        transition: transform 0.3s $easeOutQuart;

        @include media-breakpoint-up(md){
            max-width: 550px;
            width: 90%;
            padding: 30px;
        }

        p{
            color:#9396a8;
        }
    }

    .country--wrapper{
        border-radius: 10px;
        padding: 10px 0;
        margin: 10px 0 30px;
        
        .wrapper__MYR{
            padding: 0 20px;

            span{
                font-size: .8rem;
                padding-left: 10px;
            }
        }

        .wrapper__US .dropdown{
            font-size: .8rem;
            padding-left: 10px;
        }

        .wrapper__arrow{
            margin-left: auto;
            margin-right: auto;
        }
    }

    .row--result{
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray-l4;

        .rate-number {
            font-size: 2.5rem;
            font-weight: 700;
            color: $color-ebony-black;

            @include media-breakpoint-up(sm) {
                font-size: 3rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 3.5rem;
            }
        }

        .bar{
            width: 90%;
            height: 2px;
            background: $color-gray-l4;
            display: inline-block;

        }

        .col--bar{
            display: inline-block;
        }
    }

    .row--btn .btn--send{
        width: 90%;
        margin: 20px 10px 0;
        height: 45px;
    }

    .row--footer{
        font-size: 0.8rem;
        display: flex;
        align-items: flex-end;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0%;

        .col--scroll{
            display: none;
            text-align: center;
            align-self: flex-end;
            padding-bottom: 30px;

            @include media-breakpoint-up(lg){
                display: block;
            }
        }

        .col--scroll .icon{
            padding-bottom: 10px;
        }
    }

    .select-dropdown{
        cursor: pointer;
    }
    
    .selection-currency {
        top: 0;
        right: 0.5rem;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.3s ease;
    }

    .selection-currency.is-show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}

/***About***/
.section2{
    color: black;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(sm){
        min-height: 80vh;
    }
    .section-bg {
        height: 120%;
        background: linear-gradient(to top, rgba(7, 3, 41, 0.795), rgba(44, 4, 4, 0))
        ,  url("/img/background/bg2.png") center center no-repeat;
        background-size: cover;
    }

    .section-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20%;
        margin: auto;
    }

    .description {
        max-width: 75%;
        margin: auto;
    }

    p{
        font-size: .9rem;
    }

    button{
        min-width: 20vw;
        max-width: 350px;
        margin-top: 20px;
        font-size: 15px;
        border-radius: .8em;
    }
}

/***Why***/
.section3{
    position: relative;
    overflow: hidden;

    .section-bg {
        height: 100%;
        background: url("/img/background/bg3.svg") bottom center no-repeat;
    }

    .bg__line {
        width: 100%;
        height: 120%;
        background: url("/img/background/bg3-line.png") bottom left no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        
        @include media-breakpoint-up(md) {
            background-position: bottom right -50%;
        }
    }

    .bg__plane {
        position: absolute;
        top: 20%;
        right: 5%;
    }

    .usp-swiper {
        overflow: visible;
    }

    .grid-usp {
        display: flex;
        
        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
            gap: 1rem;
        }
        
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
    }

    .item{
        display: flex;
        text-align: center;
        justify-content: center;
        position: relative;
    }

    .item-wrapper{
        border-radius: 50%;
        background-color: $secondary-color;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 5px 15px rgb(235, 193, 193);

        img{
            max-width: 100%;
            max-height: 50%;
            display: block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 10px;
        }

        h3{
            font-size: .9rem;
            font-weight: bold;
            margin-top: 5px;

            @include media-breakpoint-down(sm){
                font-size: .7rem;
            }
        }
        p{
            margin: 0;
            max-width: 120px;
            margin: 0 auto;

        }
    }

    .item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .content-hide{
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s $easeInOutQuart;

        p{
            font-size: .6rem;
        }
    }
    
    .item-content:hover{
        .content-hide{
            max-height: 100%;
            opacity: 1;
            visibility: visible;
        }
    }

    .row--content{
        padding: 20px 0 30px;
    }

    .row--btn{
        text-align: center;
        padding-bottom: 20vh;
        button{
            min-width: 20vw;
            max-width: 350px;
            background: $primary-color;
            font-size: 15px;
            color: $secondary-color;
            border-radius: 10px;
            padding: 0.5rem 2rem;
        }
    }

    .row--hidden {
        width: 100%;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
}

/***How***/
//Small dots visibility base on different window sizes
@keyframes expand {
    100% { width: 1000%; }
  }

@media only screen and (max-width: 991px){
    .item .item__step::after{
        content: '';
        background: none !important;
        display: none !important;
        margin: 0;
    }    

}

@media only screen and (min-width: 556px) and (max-width: 767px){
    .item--2{
        .item-wrapper::before{
            left: 35% !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .item--2{
        .item-wrapper::before{
            left: 25% !important;
        }
    }

    .sub-wrapper{
        width: 230% !important;
    }
}


@media only screen and (min-width: 781px) and (max-width:837px){
    .item--hidden .sub-wrapper{
        border-radius: 1em;
        width: 300px !important;
        height: 80px;
        background-color: $secondary-color;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}

@media only screen and (max-width: 949px){
    .item--2.show .item-step::after{
        content: '';
        display: none !important;
        margin: 0;
    }

    .item--3.show .item-step::after{
        display: contents !important;
    }
}


.section4{
    position: relative;
    background: url("/img/background/bg4.svg") bottom center no-repeat;

    .grid-usp {
        display: grid;
        grid-template-columns: 70%;
        gap: 4rem;
        justify-content: center;
        position: relative;

        @include media-breakpoint-up(sm) {
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            gap: 1rem;
        }
    }
    
    .grid-usp > * {
        max-width: 200px;
        width: 70%;
        margin: auto;

        @include media-breakpoint-up(lg) {
            max-width: none;
            width: 18%;
            margin: 0;
        }
    }

    .item-wrapper{
        position: relative;
    }

    .item-step{
        width: 100%;
        position: absolute;
        top: -1rem;
        z-index: 5;
        
        @include media-breakpoint-up(md) {
            top: -2.5rem;
        }
        
        span {
            width: 2rem;
            height: 2rem;
            background-color: $primary-color;
            color: $secondary-color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            
            @include media-breakpoint-up(md) {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &::before,
        &::after{
            content: '';
            background: url('/img/components/component-red-dot.png');
            background-repeat: repeat-x;
            width: calc(50% + 1rem);
            height: 10px;
            display: none;
            position: absolute;
            top: 60%;
            transform: translateY(-50%);
            
            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }

        &:before {
            width: 50%;
            right: 55%;
        }

        &:after {
            left: 50%;
        }
    }

    .item:first-child .item-step{
        &::before{
            display: none;
        }
    }

    .item:last-child .item-step{
        &::after{
            display: none;
        }
    }
    
    .item.show .item-step::after {
        width: 160%;
    }
    
    // temporary fix
    .item--3.show .item-step::after {
        width: 60%;
    }
    
    .item-content{
        border-radius: 50%;
        background-color: $secondary-color;
        margin: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 5px 15px rgb(235, 193, 193);
        margin-top: 10%;
        img{
            max-width: 100%;
            max-height: 50%;
            display: block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
        }

        h3{
            font-size: .9rem;
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: auto;
        }

    }

    .item--2 .item-content{
        z-index: 3 !important;
        &::after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: -20%;
            border-radius: 50%;
            z-index: 2;
        }
    }

    .item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;

        >:first-child {
            margin-top: auto;
        }

        >:last-child {
            margin-bottom: auto;
        }
    }

    .item-empty {
        display: none;
    }

    .item--2.show + .item-empty {
        @media screen and (min-width: 496px) and (max-width: 992px)  {   
            display: block;
        }
    }

    .item--hidden.show{
        position: relative;
        opacity: 1;
        visibility: visible;
        overflow: visible;
    }

    .item--hidden{
        width: 0;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        
        @include media-breakpoint-only(md){
            display: block;
            text-align: center;
        }

        @include media-breakpoint-between(md, lg) {
            height: 200px;
        }

        .sub-wrapper{
            width: 100%;
            background-color: $secondary-color;
            height: 50%;
            border-radius: 1em;
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            position: relative;
            
            @include media-breakpoint-only(md) {
                width: 40vw !important;
                height: 41%;
                margin: auto;
            }
            
            @include media-breakpoint-up(lg) {
                position: absolute;
                height: 41%;
                width: 140%;
                right: 0;
            }
        }

        .sub-wrapper--1{
            margin-top: -1rem;

            @include media-breakpoint-only(md){
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                top: 10%;
            }
        }

        .sub-wrapper--2{
            bottom: 0;
        }

        .sub-content{
            text-align: center;
            padding: 20px;
            padding-left: 3rem;
            display: flex;
            margin-left: 10%;
            align-items: center;
            position: relative;
            
            @include media-breakpoint-up(lg) {
                margin-left: 25%;
            }
            
            img{
                align-self: center;             
            }
        }

        .sub_text{
            display: inline-block;
            font-size: .65rem;
            text-align: left;
            margin-left: 1rem;
        }
    }

    .sub-wrapper--number{
        position: absolute;
        width: 35px;
        height: 35px;
        color: $secondary-color;
        background-color: $primary-color;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: .8rem;
        z-index: 5 !important;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.3s ease;
    }

    .show.sub-wrapper--number {
        transition-delay: 0.4s;
    }

    .num-1{
        top: 25%;
        left: 0%;
        transform: scale(0);
        
        @include media-breakpoint-up(md) {
            top: 40%;
        }
    }

    .num-2{
        top: 25%;
        left: 0%;
        
        transform: scale(0);
        @include media-breakpoint-up(md) {
            top: auto;
            bottom: 40%;
        }
    }

    .show .sub-wrapper--number {
        opacity: 1;
    }

    .item__toggle{
        position: absolute;
        z-index: 4 !important;
        bottom: -15%;

        &::after{
            content: 'Click here for more info';
            font-size: .6rem;
            color: #3F476C;
            display: block;
        }

        a{
            cursor: pointer;
        }
    }

    .item__toggle.show{
        &::after{
            content: 'Close';
            font-size: .6rem;
            color: #3F476C;
            display: block;
        }
    }

    .item-shadow {
        position: absolute;
        width: 90%;
        height: 100%;
        background-color: $color-light-gray;
        border-radius: 50%;
        z-index: 2;
        right: -15%;
        bottom: 0;
        opacity: 0;
        transition: all 0.3s ease;

        &.show {
            opacity: 1;
        }
    }

}

/***Rate-Comparison***/
.section5{
    background: url('/img/background/bg5.png') bottom center no-repeat;
    background-size: cover;

    .container-fluid--1{
        position: relative !important;
    }

    .content-title{
        font-size: 1.4rem;
        font-weight: bold;
    }

    .content-date{
        font-size: 1rem;
        color: #FF0016;
        align-self: center;
        text-align: right;
        opacity: .6;
        margin: 20px 0;
        opacity: .5;
    }

    .container-fluid{
        border-radius: 2em;
        background: #F8F8F8;
        padding: 30px 5%;
    }
    
    .row--content{
        padding-top: 5vh !important;
        position: relative;
        min-height: 110vh;
        overflow: visible !important;
    }

    table{
        border-collapse: separate;
        border-spacing: 0px 1rem;
    }

    thead{
        color: #969696;
        font-size: 1rem;
        font-weight: bold;
        text-align: start;
    }

    thead th{
        text-align: center;
        vertical-align: middle;

        img{
            padding-right: 10px;
        }
    }

    tbody{
        text-align: center;
        align-items: center;
        align-self: center;
        align-content: center;
    }

    tbody tr{
        background-color: $secondary-color;
        position: relative;
    }

    tbody td{
        align-items: center;
        padding: 0 2%;
        vertical-align: middle;
        position: relative;
        font-size: 1.5rem;
        font-weight: bold;
        color: $color-gray;
        text-align: center;
        width: 20vw;

        @include media-breakpoint-down(sm){
            font-size: 1rem;
            padding: 0 20px;
            width: 60px;
        }

    }

    tbody td:last-child{
        background: $secondary-color;
        margin-bottom: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    tbody th{
        vertical-align: middle;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    // For Sunway Money row
    .item--primary td{
        color: $primary-color !important;
    }

    tbody td::before{
        content: '';
        height: 4vh;
        width: 1px;
        background-color: $color-gray-l4;
        position: absolute;
        display: flex;
        left: 0;
        top: 30%;
    }

    .row--swipe{
        .col{
            text-align: center;
        }

        p{
            font-size: .8rem;
            display: inline-block;
        }

        img{
            width: 20px;
            height: auto;
            margin-left: 2px;
        }

        @include media-breakpoint-up(sm){
            display: none;
        }
    }

    .dropdown{
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        align-self: center;
        cursor: pointer;
    }
    
    .dropdown__text{
        padding-left: 10px;
        display: inline-block;
        position: relative;

        span{
            font-size: .9rem;
            color: $color-gray-l2;
        }
    }

    .dropdown-content{
        padding: 5px 10px;
        border-radius: .5em;
        width: 60%;
        z-index: 2;
    }

    .dropdown-default{
        font-size: .8rem;
        font-weight: bold;
    }

    .dropdown-default::after{
        content: url('/img/components/component-dropdown_arrow.png');
        padding-left: 5px;
    }

    .selection-currency {
        top: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.3s ease;
    }

    .selection-currency.is-show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    .col--TC{
        font-size: .9rem;
        margin-top: 5vh;
        text-align: center;
        @include media-breakpoint-up(sm){
            text-align: end;    
            margin-top: 0;
        }
    }
}

/***Review & Rating***/
.section6{
    .content-wrapper{
        display: flex; 
        align-items: center;
        margin: 10px 0;
    }

    .content-text-wrapper{
        font-size: .8rem;
        display: inline;
        padding: 10px;

        h5, h1{
            font-weight: bold;
            color: $primary-color;
        }
    
        p{
            font-size: .7rem;
            color: #8a8a8a;
            margin: 0;
        }
    }
}

/***Customer Comment***/
.section7{
    padding-bottom: 10%;
    background: url('/img/background/bg6.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: cover;

    @include media-breakpoint-up (xxl) {
        padding-bottom: 15%;
    }

    .row--title{
        @include media-breakpoint-down(sm){
            padding-top: 5vh !important;
        }

        p{
            padding: 30px 0 !important;

            @include media-breakpoint-down(sm){
                padding: 2vh !important;
                margin: auto;
            }
        }
    }

    .comment-wrapper{
        background-color: $secondary-color;
        border-radius: 10px;
        padding: 20px;
        padding-right: 10px;
        margin: 10px;
        margin-bottom: 40px;
        position: relative;
        text-align: left;
        img{
            position: absolute;
            top: 5px;
            left: 15px;
        }
        p{
            font-size: .8rem;
            color: $color-gray-l2;
            padding: 20px;
            padding-right: 15px;
            
            @include media-breakpoint-up(md) {
                height: 12vh;
            }
        }

        &::after{
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            background-color: $secondary-color;
            transform: rotate(45deg);
            left: 30px;
        }
    }

    .customer-wrapper{
        display: flex;
        align-items: center;
        margin-left: 20%;
    }

    .text-wrapper{
        padding-left: 10px;
        h3{
            font-weight: bold;
            font-size: 1.3rem;
        }

        p{
            font-size: .7rem;
            color: $color-gray-l1;
        }
    }

    .carousel-indicators{
        margin: 0;
    }

    .carousel-indicators li{
            border: 1px solid $primary-color;
            border-radius: 50%;
            width: 10px;
            height: 10px;
    }

    .carousel-indicators .active{
        border: 1px solid $primary-color;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: $primary-color;
    }

    .carousel{
        padding-bottom: 13vh;
    }

    .col--review{
        padding: 0 20px;
    }
}

.section8{
    .row--content{
        @include media-breakpoint-down(sm){
            padding-top: 5%;
        }
    }
}

/* @media only screen and (max-width: 576px){
    .section9{
        background-image: url('/img/background/bg-smartphone.svg') !important;
        background-position-x: center !important;
        background-position-y: 10% !important;
    }
}
 */

 @media only screen and (min-width: 576px){
    .section9{
        background-image: url('/img/background/bg9.svg');
        background-repeat: no-repeat;
        background-position-y: 20vh;
        background-position-x: right;
    }
 }

 @media only screen and (max-width: 1228px) and (min-width:576px) {
    .section9{
        background-position-x: 70%;
        background-position-y: 40vh;
        height: 100vh !important;
        .row--title{
            display: none;
        }

        .img--chracter-4{
            display: none !important;
        }

        .row--hidden{
            display: block !important;
            padding-top: 5% !important;
            margin: auto;

            h2{
                font-weight: bold;
                text-align: center;
            }

            .download-store{
                text-align: center;
            }

            .col{
                margin: auto;
            }
        }
    }

}
/***Send Money Globally***/
.section9{
    position: relative;
    overflow: hidden;
    height: 600px;

    @include media-breakpoint-up(sm){
        .section9--phone-size{
            display: none !important;
        }

        .section9--default-size{
            display: block !important;
        }
    }

    .row--title{
        text-align: left;
        padding-top: 10vh;
        h2{
            text-align: center;
        }
    }

    .row--hidden{
        display: none;

        .downlaod-store img{
            margin: 0 2% !important;
        }
    }

    .img{
        display: flex;
        justify-content: center;
    }

    .img a{
        margin: 0 !important;

        .logo-appstore{
            margin-top: 22%;
        }
    }

    .section9--phone-size .img--download img{
        margin: 0px 10px;
    }

    .section9--default-size{
        display: none;

        .col--content{
            text-align: left;
        }

        .col--content h2{
            padding-top: 20%;
            text-align: left;
        }

        .img{
            position: absolute;
        }


        .img--chracter-4{
            left: 2%;
            bottom: -20%;
        }
    }


    .row--download{
        padding-bottom: 20vh;
    }

    .download-wrapper p{
        font-size: .7rem;
        color: $color-gray-l1;
    }

    .download-store img{
        margin: 0 2%;

        @include media-breakpoint-up(xl) {
            margin: 0;
            margin-right: 5% !important;
        }
    } 

    .smartPhone{
        position: absolute;
        left: 52%;
        transform: translateX(-50%);
        bottom: -25%;
    }
}

//For the circle flags
.selected-flag{
    width: 45px;
    height: auto;
    border: 3px solid $color-gray-l3;
    border-radius: 50%;

    img{
        width: 100%;
        height: auto;
    }
}
