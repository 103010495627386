/** ============================================================================
 * @components Swiper
 * ========================================================================== */

.carousel-3d-wrapper {
    min-height: 80vw;

    @include media-breakpoint-up(sm) {
        min-height: 0;
    }
}

.carousel-3d {
    width: 100%;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    z-index: 1;

    // Overwrite swiper slide
    .swiper-slide {
        width: 90vw;
        height: auto;
        flex-shrink: 0;
        position: relative;
        padding: 0 15px;
        
        @include media-breakpoint-up(sm) {
            width: 60vw;
        }
        
        @include media-breakpoint-up(lg) {
            width: 50vw;
        }
    }
    
    .slide__img img {
        max-width: 100%;
        height: auto;

        @include media-breakpoint-up(xl){
            display: block;
            margin: auto;
        }

        @include media-breakpoint-down(sm){
        }
    }

    .slide__text {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        
        @include media-breakpoint-up(sm) {
            color: white;
            bottom: 10%;
            opacity: 1;
        }
    }

    .slide__text p {
        max-width: 90%;
        margin: auto;
        font-size: .8rem;
        
        @include media-breakpoint-up(sm) {
            max-width: 65%;
        }
    }

    .slide__text h4 {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .swiper-slide-duplicate-active,
    .swiper-slide-duplicate-next,
    .swiper-slide-duplicate-prev,
    .swiper-slide-active ~ .swiper-slide-duplicate-next ~ .swiper-slide {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 45%;
        cursor: pointer;
        z-index: 10;
        transform: translateY(-50%);
    }

    .swiper-button-prev {
        left: 0;
        right: auto;
        
        @include media-breakpoint-up(lg) {
            left: 2rem;
        }
    }
    
    .swiper-button-next {
        right: 0;
        left: auto;

        @include media-breakpoint-up(lg) {
            right: 2rem;
        }
    }

    .swiper-slide-active {
        .slide__text {
            opacity: 1;
            margin: 1rem auto;
        }
    }

    &.swiper-container-horizontal > .swiper-pagination {
        bottom: -20vw;

        @include media-breakpoint-up(sm) {
            bottom: 0;
        }
    }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}


// Pagination
.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    width: 100%;
    bottom: 10px;
    left: 0;
}


// bullets
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	background: transparent;
    border-radius: 50%;
	border: 1px solid $primary-color;
	display: inline-block;
    margin: 0 0.25rem;
	opacity: 1;
    transition: 0.2s;
}

.swiper-pagination-bullet-active {
    background-color: $primary-color;
    opacity: 1;
}