/** ============================================================================
 * @components Form
 * ========================================================================== */

.input--gray {
    background-color: $color-gray-l4;
    border: 1px solid $color-gray-l3;
}

.input--light-gray {
    background-color: $color-gray-l5;
    border: 1px solid $color-gray-l4;
}


