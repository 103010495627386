/** ============================================================================
 * @components Custom Scrollbar
 * ========================================================================== */

.custom-scrollbar,
[data-disable-scroll] {
    padding-right: 1rem;
    -webkit-overflow-scrolling: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-gray-l4; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-gray-l3; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: darken($color-gray-l3, 10%); 
    }
}

.scrollbar--thin {
    &::-webkit-scrollbar {
        width: 1px;
    }
}