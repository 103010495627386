$primary-color: #ff0016;
$primary-color-l1: lighten($primary-color, 10%);
$primary-color-l2: lighten($primary-color, 20%);
$primary-color-l3: lighten($primary-color, 30%);
$primary-color-l4: lighten($primary-color, 40%);
$primary-color-l5: lighten($primary-color, 45%);

$secondary-color: #fff;

// other colors
$color-ebony-black: #2C3045;

$color-gray: gray;
$color-gray-l1: lighten(gray, 10%);
$color-gray-l2: lighten(gray, 20%);
$color-gray-l3: lighten(gray, 30%);
$color-gray-l4: lighten(gray, 40%);
$color-gray-l5: lighten(gray, 45%);

$color-light-gray: #EFEFEF;

$color-dark-blue: #2C3045;

$color-black: #000;
