/** ============================================================================
 * @components T & C
 * ========================================================================== */

.notice-wrapper{
    position: relative;
    padding: 5%;
    border: 1px solid $color-gray-l3;
    border-radius: 3em;
    margin-bottom: 5% !important;

    @include media-breakpoint-up(sm){
        margin: 10%;
    }
}

.row--header{
    h3{
        padding-top: 30px;
        color: $primary-color;
        font-size: 1.5rem;
    } 

    h4{
        font-size: 1rem;
        font-weight: bold;
    }
    
    p{
        font-size: .8rem;
        max-width: 80%;
        margin: auto;
    }
}

.row--header.eng{
    h3{
        padding-top: 10%;

        @include media-breakpoint-up(lg){
            padding-top: 15% !important;
        }
    }
}

.row--content{
    padding-top: 15vh;

    @include media-breakpoint-up(lg){
        padding-top: 5vh;
    }
}

.col--notice{
    text-align: center;
}

.account-type{
    display: flex;
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm){
        top: -30%;
        .type{
            width: 180px !important;
            height: 190px !important;

            img{
                margin-top: 20% !important;
                margin-bottom: 10% !important;
            }

            h4{
                font-size: 1.5rem !important;
            }
        }
        .type--business img{
            margin-top: 25% !important;
        }
    }
    .type{
        margin: 10px;
        text-align: center;
        background-color: $secondary-color;
        border: 1px solid $color-gray-l3;
        border-radius: 1em;
        cursor: pointer;
        width: 120px;
        height: 120px;
        img{
            display: block;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        h4{
            font-size: 1rem;
            margin-top: auto;
            margin-bottom: 10px;
        }
    }
    
    .active{
        color: $primary-color !important;
        border-color: $primary-color !important;
        box-shadow: 0px 0px 20px rgb(235, 193, 193);
    }

    .type--business{
        img{
            margin-top: 20px;
        }
    }
}

.col--text p{
    font-size: .8rem;
}
    
.row--sub-title{
    padding: 3% 0;

    h4{
        font-size: 1rem;
        font-weight: bold;
    }
}

.row--list{
    
    ol.non-numbered{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ol.numbered{
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol.alphabet{
        list-style-type: lower-alpha;
        margin: 10px;
        padding: 10px;
        margin-top: 1em;
    }
    
    ol.first{
        font-weight: bold !important;
    }

    ol.numbered ol{
        font-weight: normal !important;
        font-size: .8rem;
        margin-top: 2vh;
    }

    ol.numbered > li.numbered{
        display: table;
        margin-bottom: 1em;
    }

    ol.numbered > li.numbered{
        counter-increment: item;
    }
      
    ol > li:before{
        display: table-cell;
        padding-right: 1em;
    }

    ol.numbered > li.numbered:before{
        content: counters(item, ".") ". ";
    }
      
    li ol > li::before{
        padding-right: 5vw;
    }

    li:not(:last-child){ 
        margin-bottom: 1rem; 
    }
}
   