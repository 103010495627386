/** ============================================================================
 * @base Utilities
 * ========================================================================== */

/* Font Size Utilities
/* -------------------------------------------------------------------------- */
.font-sm {
    font-size: 0.8rem;
}

.font-md {
    font-size: 1.2rem;
}

.font-lg {
    font-size: 1.5rem;
}

/* Font Size Utilities
/* -------------------------------------------------------------------------- */
.font-weight-250{
    font-weight: 250 !important;
}

.font-weight-400{
    font-weight: 400 !important;
}

.font-weight-500{
    font-weight: 500 !important;
}

.font-weight-550{
    font-weight: 550 !important;
}

.font-weight-600{
    font-weight: 600 !important;
}

 /* Color Utilities
/* -------------------------------------------------------------------------- */
.color-white {
    color: white;
}

.color-primary {
    color: $primary-color !important;
}

.color-primary-l1 {
    color: $primary-color-l1 !important;
}

.color-primary-l2 {
    color: $primary-color-l2 !important;
}

.color-primary-l3 {
    color: $primary-color-l3 !important;
}

.color-primary-l4 {
    color: $primary-color-l4 !important;
}

.color-primary-l5 {
    color: $primary-color-l5 !important;
}

.color-secondary {
    color: $secondary-color !important;
}

.color-gray {
    color: $color-gray !important;
}

.color-gray-l1 {
    color: $color-gray-l1 !important;
}

.color-gray-l2 {
    color: $color-gray-l2 !important;
}

.color-gray-l3 {
    color: $color-gray-l3 !important;
}

.color-gray-l4 {
    color: $color-gray-l4 !important;
}

.color-gray-l5 {
    color: $color-gray-l5 !important;
}

.color-black {
    color: $color-black;
}


/* Background Utilities
/* -------------------------------------------------------------------------- */
.bg-primary {
    background-color: $primary-color !important;
}

.bg-secondary {
    background-color: $secondary-color !important;
}

.bg-gray {
    background-color: $color-gray !important;
}

.bg-gray-l1 {
    background-color: $color-gray-l1 !important;
}

.bg-gray-l2 {
    background-color: $color-gray-l2 !important;
}

.bg-gray-l3 {
    background-color: $color-gray-l3 !important;
}

.bg-gray-l4 {
    background-color: $color-gray-l4 !important;
}

.bg-gray-l5 {
    background-color: $color-gray-l5 !important;
}

.bg-light-gray{
    background-color: $color-light-gray !important;
}

.bg-white {
    background-color: white;
}

.bg-transparent {
    background-color: transparent;
}


// Background Position
.bg-position-top {
    background-position-y: top;
}

.bg-position-center {
    background-position-y: center;
}

.bg-position-bottom {
    background-position-y: bottom;
}


/* Layout Size Utilities
/* -------------------------------------------------------------------------- */
.min-height-80vh {
    min-height: 80vh;
}
.min-height-100vh {
    min-height: 100vh;
}

.min-height-120vh {
    min-height: 120vh;
}

/* Position Utilities
/* -------------------------------------------------------------------------- */
.absolute-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.absolute-center-x {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.absolute-center-y {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.object-position-end {
    object-position: right !important;
}

.bg-center {
    background-position: center;
}

.transform-center {
    left: 50%;
    transform: translateX(-50%);
}

.sticky-top {
    position: sticky;
    top: 0;
}


/* Border Radius Utilities
/* -------------------------------------------------------------------------- */
.rounded-xl {
    border-radius: 0.5rem !important;
}

.rounded-xxl {
    border-radius: 1rem !important;
}

.rounded-3xl {
    border-radius: 2rem !important;
}

.rounded-4xl {
    border-radius: 3rem;
}

/* Box Shadow Utilities
/* -------------------------------------------------------------------------- */
.shadow-gray {
    box-shadow: 0px 3px 20px rgba($color-gray-l2, 0.5);
}

.shadow-dark{
    box-shadow: 0px 3px 20px rgb(53, 53, 53);
}

.shadow-red{
    box-shadow: 0px 5px 12px rgb(107, 41, 41);
}

.shadow-pink{
    box-shadow: 0px 5px 15px rgb(235, 193, 193);
}


/* Visibility Utilities
/* -------------------------------------------------------------------------- */
.is-show {
    opacity: 1;
    visibility: visible;
}

.is-hide {
    opacity: 0;
    visibility: hidden;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.z-negative {
    z-index: -1;
}

.backface-hidden {
    backface-visibility: hidden;
}

.no-pointer-event {
    pointer-events: none;
}


/* Ratio Utilities
/* -------------------------------------------------------------------------- */
.ratio {
    width: 100%;
    position: relative;

    &:before {
        content: "";
        display: block;
        padding-top: var(--aspect-ratio);
    }

    & > * {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.ratio-1x1 {
    --aspect-ratio: 100%;
}

.ratio-16x9 {
    --aspect-ratio: calc(9 / 16 * 100%);
}



/* Title Utilities
/* -------------------------------------------------------------------------- */
.row--title {
    display: flex;
    text-align: center;
    padding-top: 15vh;

    @include media-breakpoint-down (sm){
        padding-top: 10vh !important;

        h1{
            font-size: 2rem !important;
        }
    }

    h1 {
        font-size: 3rem;
        font-weight: bold;
    }

    p {
        font-size: .9rem;
    }
}

/* Content Utilities
/* -------------------------------------------------------------------------- */
.row--content {
    padding-top: 10vh;
    padding-bottom: 10vh;
    overflow: hidden;

    @include media-breakpoint-down(sm){
        padding-bottom: 5vh !important;
    }
}

/* Animation
/* -------------------------------------------------------------------------- */

.fade-in {
    opacity: 50%;
    transition:opacity 0.8s;
}

.fade-in:hover {
    opacity: 100%;
    transition:opacity 0.5s;
}

/* Text align
/* -------------------------------------------------------------------------- */
.text-align-center{
    text-align: center;
}

/* Text decoration
/* -------------------------------------------------------------------------- */
.text-decoration-none{
    text-decoration: none;
}