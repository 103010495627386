/** ============================================================================
 * @pages FAQ
 * ========================================================================== */
 .faq{
    background: url('/img/background/bg-faq.svg') no-repeat;
    background-position: 80% 110%;

    .category--btn{
        display: block;

        @include media-breakpoint-up(lg){
            justify-content: center;
        }
    }

    .row--content{
        @include media-breakpoint-down(md) {
            padding: 10% 0 !important;
        }
    }

    .col--questions ul{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-header{
        border: 0px;
        padding: 0;
    }

    .col--questions{
        
        @include media-breakpoint-down(md){
            display: none;
        }

        @include media-breakpoint-up(lg){
            display: block;
        }
    }

    .col--category button{
        display: block;
        color: $primary-color;
        background-color: $secondary-color;
        border: 1px red solid;
        border-radius: 10px;
        padding: 10px;
        margin: auto;
        margin-top: 8%;
        margin-bottom: 8%;
        width: 100%;
        
        @include media-breakpoint-down(md){
            text-align: left;
        }

        &:hover{
            color: $primary-color;
        }
    }

    .col--category button.active{
        color: $secondary-color;
        background-color: $primary-color;
        box-shadow: 0px 5px 15px rgb(235, 193, 193);
    }


    .question-wrapper{
        width: 100%;
        border-radius: 1.2em;
        background-color: $secondary-color;
        cursor: pointer;
        padding: 2% 5%;
        margin-bottom: 3%;

        @include media-breakpoint-down(md){
            padding: 5%;
        }
    }

    .question-wrapper.inactive{
        > * {
            opacity: .5;
        }
    }

    .question-default{
        display: none;
        opacity: 0;
    }

    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    .question-mobile{
        display: none;
    }

    @include media-breakpoint-down (md){
        .question-mobile.active{
            display: block;
            animation: fadeIn ease 1s;
            animation-fill-mode: forwards; 
        }
    }

    .col--questions .question-default.active{
        display: block;
        animation: fadeIn ease 1s;
        animation-fill-mode: forwards; 
    }

    .question-wrapper:first-child {
        margin-top: 2%;
    }

    .question-default > .question-wrapper:not(:last-child) {
            margin-bottom: 3%;
    }

    .question{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .question h4{
        font-size: 1rem;
        margin: 0;
        font-weight: 700;
        padding-right: 20px;
    }

    .question__ans {
        color: $color-gray-l2;
        font-size: .8rem;
        margin-top: 2%;
        max-width: 40vw;
        margin-bottom: 0;
        display: none;

        p{
            margin: 0;
        }

        @include media-breakpoint-down(md){
            max-width: 55vw;
        }
    }

    .question--btn img{
        height: 20px;
        width: 20px;
        cursor: pointer;

        @include media-breakpoint-down(sm){
            width: 15px;
            height: auto;
        }
    }


    thead{
        background-color: $color-gray-l2;
        color: $secondary-color;
    }
    
    thead tr th:first-child{
        width: 20vw;
        padding: 5%;
    }
    
    thead tr th:last-child{
        width: 80px;
        text-align: center;
    }
    
    table, th, td {
        border: 1px solid $color-gray-l1;
        border-collapse: collapse;
    }
    
    td{
        padding: auto;
    }
    
    td:first-child{
        padding: 2% 0;
        padding-left: 5%;
    }
    
    td:last-child{
        text-align: center;
    }

    .wrapper{
        text-align: center;
        padding: 5%;
        border: 1px solid $color-gray-l3;
    }

    ul.dotted li.dotted{
        margin-left: 5%;
        margin-bottom: 3%;
    }
}
