/** ============================================================================
 * @components Triangle
 * ========================================================================== */

// Default triangle size
$triangle-size: 0.35rem;

// Mixin for Triangle individual
// Variables: $size, $color
@mixin triangle-left($size: $triangle-size, $color: $primary-color) {
    width: $size;
    height: $size * 2;
    border-right: solid $size $color;
    border-bottom: solid $size transparent;
    border-top: solid $size transparent;
    display: inline-block;
}

// Mixin for Triangle Pseudo Styles - apply for parent element
// Variables: $size, $color
@mixin triangle-left-pseudo($size: $triangle-size, $color: $primary-color) {
    position: relative;
    margin-right: $size;

    &:after {
        content: "";
        @include triangle-left($size, $color);
        position: absolute;
        top: 50%;
        right: -$size * 2;
        transform: translateY(-50%);
    }
}

.triangle-left {
    @include triangle-left;
}

.triangle-left-pseudo {
    @include triangle-left-pseudo;
    
    &--black {
        @include triangle-left-pseudo($triangle-size, $color-ebony-black);
    }
}