/** ============================================================================
 * @components Buttons
 * ========================================================================== */

// Mixin for underline button
// Variables: $color
@mixin button-underline($color) {
    color: $color;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $color;
        position: absolute;
        top: 110%;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.3s ease;
    }

    &:hover {
        color: darken($color, 10%);
        text-decoration: none;

        &:before {
            width: 50%;
            background-color: darken($color, 10%);
        }
    }
}

// Mixin for solid button
// Variables: $bg-color, $text-color
@mixin button-solid($bg-color, $text-color) {
    color: $text-color;
    background-color: $bg-color;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px rgba($bg-color, 0.4);
    transition: all 0.3s ease;
    will-change: transform;

    svg path {
        transition: all 0.3s ease;
        will-change: fill;
    }

    &:hover {
        color: $text-color;
        background-color: lighten($bg-color, 5%);
        text-decoration: none;
        box-shadow: 0px 7px 15px rgba($bg-color, 0.4);
        transform: translateY(-2px);

        svg path {
            fill: $text-color;
        }

        .triangle-left {
            border-right-color: $text-color;
        }
    }
}

// Mixin for border button
// Variables: $border-color, $hover-color
@mixin button-border($border-color, $hover-color) {
    background-color: transparent;
    color: $border-color;
    border: 1px solid $border-color;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 5px 10px rgba($border-color, 0.1);
    transition: all 0.3s ease;
    will-change: transform;

    svg path {
        fill: $border-color;
        transition: all 0.3s ease;
        will-change: fill;
    }

    &:hover {
        color: $hover-color;
        background-color: $border-color;
        text-decoration: none;
        box-shadow: 0px 7px 15px rgba($border-color, 0.4);
        transform: translateY(-4px);

        svg path {
            fill: $hover-color;
        }

        .triangle-left {
            border-right-color: $hover-color;
        }
    }
}

// Mixin for arrow inside button
// Variables: $color
@mixin icon-arrow($color)  {
    .button__prev-icon,
    .button__next-icon {
        width: 100%;
        height: 100%;
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .button__prev-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23#{$color}'%2F%3E%3C%2Fsvg%3E");
    }
    
    .button__next-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23#{$color}'%2F%3E%3C%2Fsvg%3E");
    }
}

.button-rounded {
    border-radius: 2rem;
    padding: 0.5rem 2rem;
    @include button-solid($primary-color, white);
}

.button-login{
    border-radius: .5rem;
    padding: 0.5rem 2rem;
    

    &.active{
        color: white;
        background-color: $primary-color

    }

    @include button-solid($secondary-color, $primary-color);
    @include button-border($primary-color, white)


}

.button-border {
    border-radius: 2rem;
    padding: 0.5rem 2rem;
    @include button-border($primary-color, white)
}

.button-circle {
    border-radius: 50%;
    @include button-solid($primary-color, white);
    @include icon-arrow('ffffff');

    &--gray {
        @include button-solid($color-gray-l4, white);
        box-shadow: 0px 5px 10px rgba($color-gray-l3, 0.8);
    }

    &--gray:hover {
        background-color: $primary-color;
        box-shadow: 0px 7px 15px rgba($primary-color, 0.4);
    }
}

.button-link {
    @include button-underline($primary-color);
    
    &--black {
        @include button-underline($color-ebony-black);
    }
    
    &--gray {
        @include button-underline($color-gray);
    }
}

.button-more {
    position: relative;
    border-radius: 2rem;
    padding: 1rem;
    transition: all 0.3s ease;

    &:hover {
        background-color: $color-gray-l3;
    }
}

[class^="button-"] {
    img + .button__text,
    svg + .button__text {
        margin-left: 1rem;
    }

    .button__text + .triangle-left {
        margin-left: 0.5rem;
    }
}

.button-lg {
    padding: 1rem 3rem;
}