/** ============================================================================
 * @pages Dashboard
 * ========================================================================== */

.dashboard-wrapper {
    display: grid;
    grid-template-columns: minmax(250px, 20vw) auto;
}

.dashboard-main {
    border-radius: 35px;
    box-shadow: 0 3px 30px rgba(black, 0.1);
}

.dashboard-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    
    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: minmax(400px, auto) minmax(200px, 20vw);
    }
    
    @include media-breakpoint-up(full-hd) {
        display: grid;
        grid-template-columns: minmax(400px, auto) minmax(250px, 15vw);
    }
}

.dashboard-content {
    width: 100%;
    padding: 0 1rem;
}