/** ============================================================================
 * @components Navigation
 * ========================================================================== */

/**********************
* Top-Navbar
**********************/
.top_nav{
    padding: 10px 0;
    background-color: $secondary-color;

    .navbar-brand img{
        padding-left: 10px;
    }

    .nav-collapse{
        flex-grow: .5 !important;
        height: 100vh;
    }

    .navbar-toggler{
        border: 1px solid $color-gray-l4;
        width: 50px;
        height: 50px;
        margin-right: 10px;

        img.hidden{
            display: none !important;
        }
    }
    
    .nav-link{
        color: $primary-color;
        font-size: .7rem;
        font-weight: bold;
        text-align: left;
    }

    .nav-login{
        display: none;
        padding-bottom: 2%;
        margin-bottom: 2%;

        .btn--login{
            max-width: 60%;
            height: 45px;
            margin: auto;
            padding-left: 6% !important;
        }

        &::after{
            content: '';
            width: 60%;
            height: 1px;
            background-color: rgb(245, 220, 220);
            position: absolute;
            display: block;
            left: 22%;
            margin-top: 3%;
        }
    }

    .nav-contact{
        display: none;
    }

    .nav-home{
        display: none;
    }

    .btn{
        border-radius: 50px;
        font-weight: bold;
        font-size: .8rem;
        text-align: center;
        padding: 0;
        height: 30px;
        display: flex;
    }

    .btn--login{
        border-color: rgb(224, 193, 193);
        color: $primary-color;
        width: 100%;
        height: 37px;
        padding-left: 15%;
        text-align: left;
        position: relative;
        align-items: center;
    }

    .btn--login img{
        padding: 5px;
        padding-left: 3px;
    }

    .btn--signup{
        background-color: $primary-color;
        color: $secondary-color;
        position: absolute;
        right: 0;
        top: 0;
        width: 52%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1069px){
    .btn--login{
        padding-left: 10% !important;
    }
}

// For collapse menu
@media only screen and (max-width: 992px){
    .navbar-nav{
        height: 100vh !important;
    }

    .top_nav .nav-link{
        color: black;
        font-size: .8rem;
        font-weight: bold;
        text-align: center !important;
        padding-left: 5%;
    }

    .top_nav .nav-link.active {
        color: $primary-color;
    }

    .nav-login{
        display: block !important;
        margin-top: 2%;
        position: relative;

    }

    .nav-home{
        display: block !important;
    }

    .nav-contact{
        display: block !important;
    }

    .col--btn{
        display: none;
    }
}


/**********************
* Dashboard Menu
**********************/
.nav-dashboard {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;

    .navbar-brand {
        display: block;
        text-align: center;
        padding: 0;
        margin-bottom: 1.5rem;
    }
}

.dashboard-menu {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
}

.dashboard-menu__item {
    position: relative;
}

.dashboard-menu__item a {
    font-weight: 500;
    color: $color-ebony-black;
    display: flex;
    align-items: center;
}

.dashboard-menu__icon {
    min-width: 5vw;
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: all 0.3s ease;
    }

    svg,
    img {
        position: relative;
        z-index: 1;
    }

    svg path,
    svg rect {
        transition: all 0.3s ease;
    }
}

.dashboard-menu__text {
    padding: 1rem;
    transition: all 0.3s ease;
}

// hover state
.dashboard-menu__item a:hover {
    color: $primary-color;
    text-decoration: none;

    .dashboard-menu__icon:before {
        transform: translateX(0);
    }
    
    .dashboard-menu__icon path,
    .dashboard-menu__icon rect {
        fill: white;
    }
}

// active state
.dashboard-menu__item.is-active {
    .dashboard-menu__icon:before {
        box-shadow: 0 5px 20px rgba($primary-color, 0.6);
        transform: translateX(0);
    }

    .dashboard-menu__icon path,
    .dashboard-menu__icon rect {
        fill: white;
    }

    .dashboard-menu__text {
        color: $primary-color;
    }
}

.dashboard-menu-footer {
    margin-top: auto;

    .toggle-dark-mode {
        display: flex;

        .icon-day,
        .icon-night {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .icon-day {
            left: 15%;
        }

        .icon-night {
            right: 15%;
        }

        .icon-day path,
        .icon-night path {
            fill: white;
        }
    }

    .links-social {
        a {
            margin-right: 0.5rem;
        }

        img,
        svg {
            max-height: 18px;
        }
    }

}

.px-nav {
    padding-left: calc(5vw - 2rem);
    padding-right: calc(5vw - 2rem);
}