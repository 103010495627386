/** ============================================================================
 * @components Currency
 * ========================================================================== */

.currency__country {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
    gap: 1rem;

    a {
        font-size: 0.85rem;
        color: $color-ebony-black;
        border: 1px solid transparent;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        padding: 0.3rem 0.6rem;
    }

    .currency__country-flag {
        max-width: 18px;
        flex: 1;
    }

    .currency__country-code {
        font-weight: 500;
        flex: 2;
        margin-left: 0.3rem;
    }

    a:hover {
        background: $color-gray-l4;
        text-decoration: none;
        border: 1px solid lighten($primary-color, 30%);
    }
}