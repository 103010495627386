@media only screen and (max-width: 568px){
    .scrollToTop{
        right: 7% !important;
    }

    .chat-bot{
        right: 5% !important;
    }
}

.chat-bot{
    position: fixed;
    right: 2vw;
    bottom: 8vh;
    z-index: 5;
    background-color: $primary-color;
    border-radius: 50%;
    border: 0;
    width: 77px;
    height: 77px;
    box-shadow: 0px 1px 25px rgba(100, 99, 99, 0.80);
}

.scrollToTop{
    position: fixed;
    right: 2.8vw;
    z-index: 5;
    bottom: 20vh;
}

