/** ============================================================================
 * @components Select
 * ========================================================================== */

.select-countries {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select__flag {
    width: 2rem;
    height: 2rem;
    border: 3px solid $color-gray-l3;
    border-radius: 50%;
    position: relative;

    img,
    svg {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.select__currency {
    font-size: 0.9rem;
    font-weight: 700;
}

.selection-currency {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    
    .search-form input {
        width: 100px;

        &:focus {
            outline: none;
        }
    }
    
    .currency__country {
        max-height: 200px;
        gap: 0.3rem;
        overflow-y: scroll;
    }

    .currency__country a {
        display: flex;
    }

    .currency__country-flag,
    .currency__country-code {
        pointer-events: none;
    }
}