/** ============================================================================
 * @components Profile
 * ========================================================================== */

$profile-size: 4rem;

.profile-image {
    width: $profile-size;
    height: $profile-size;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 0px transparent;
    transition: box-shadow 0.3s ease;
    will-change: box-shadow;

    &:hover {
        box-shadow: 0px 0px 0px 3px $color-gray-l3;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}