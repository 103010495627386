/** ============================================================================
 * @components Table
 * ========================================================================== */

.table-align-middle {
    th, td {
        vertical-align: middle;
    }
}

.table-border-0 {
    border: 0;

    thead th,
    th,
    tr,
    td {
        border: 0;
    }

    sup {
        font-size: 70%;
    }
}

.table-striped--white {
    tbody tr:nth-of-type(odd) th,
    tbody tr:nth-of-type(odd) td {
        background-color: white;
    }
}

.table-striped--rounded {
    tbody tr:nth-of-type(odd) th:first-child,
    tbody tr:nth-of-type(odd) td:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    tbody tr:nth-of-type(odd) th:last-child,
    tbody tr:nth-of-type(odd) td:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}

.table__name {
    min-width: 120px;
}