/** ============================================================================
 * @pages Login
 * ========================================================================== */

.login{
    background-image: url('/img/background/bg-login-1.svg');
    background-position-y: 150%;
    background-position-x: center;
    background-repeat: no-repeat;
}

.login-wrapper{
    margin-top: 15vh;
    min-height: 70vh;
    position: relative;
}

.login-wrapper button svg{
    width: 20px;
    height: 20px;
}

#login-email{
    width: 80%;
}

.link--sign-up{
    &:hover{
        color: inherit;
    }
}
