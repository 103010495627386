/** ============================================================================
 * @components Toggle
 * ========================================================================== */

// Button Colors
$btn-default-color: white;
$btn-default-bg: $color-ebony-black;

// Toggle Sizes
$toggle-default-size: 1.5rem;


// Mixin for Switch Colors
// Variables: $color, $bg, $active-bg
@mixin toggle-color($color: $btn-default-color, $bg: $btn-default-bg, $active-bg: $primary-color) {
    color: $color;
    background: $bg;

    &.active {
      background-color: $active-bg;
    }
}


// Mixin for Default Switch Styles
// Variables: $size
@mixin toggle-mixin($size: $toggle-default-size) {
    width: $size * 2;
    height: $size;
    margin: 0;
    padding: 0;
    position: relative;
    border: none;
    border-radius: $size;
    
    &:focus,
    &.focus {
      &,
      &.active {
        outline: none;
      }
    }
    
    > .handle {
      position: absolute;
      top: ($size * .25) / 2;
      left: ($size * .25) / 2;
      width: $size * .75;
      height: $size * .75;
      border-radius: $size * .75;
      background: #fff;
      transition: left .25s;
    }

    &.active {
      transition: background-color .25s;

      > .handle {
        left: $size + (($size * .25) / 2);
        transition: left .25s;
      }
    }    
}


// Apply Mixin to different sizes & colors
.btn-toggle {
    @include toggle-mixin();
    @include toggle-color();
}