/** ============================================================================
 * @base Grid
 * ========================================================================== */

@mixin grid($percentage: 30%) {
    display: grid;
    grid-template-columns: minmax($percentage, min-content) auto;
}

.grid-55 {
    @include grid(55%);
}

.grid-md-55 {
    @include media-breakpoint-up(md) {
        @include grid(55%);
    }
}

.grid-lg-55 {
    @include media-breakpoint-up(lg) {
        @include grid(55%);
    }
}