/** ============================================================================
 * @pages About Us
 * ========================================================================== */
@media only screen and (max-width: 568px){
    .title__icon img{
        max-width: 80% !important;
    }

    .about-section3 img{
        max-width: 80% !important;
    }
}

body{
    overflow-x: hidden;
}

.about-main{
    position: relative;
    overflow: hidden;

    .section-bg {
        height: 120%;
        background: url('/img/background/bg2.png') center no-repeat;
        background-size: cover;
    }
}

.about-section1{
    background: url('/img/characters/building.svg') center bottom no-repeat;
    overflow: visible;
    position: relative;
    height: 800px;
}

.about-section2{
    background-color: $color-light-gray;
}

.about-section2 .row--title{
    padding: 20vh 0 10vh !important;
}

.about-section2 .copy__link{
    display: inline-block !important;
}

.about-section2 p{
    max-width: 50vw;
}

.about-section3{
    padding-bottom: 10vh;
}

.about-section3 img{
    padding: 1.5em;
}

.about-section3 h1{
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
}

.row--title{
    text-align: center;
}

.row--title p{
    max-width: 70%;
    margin: auto;
}

.img{
    display: none;
}

.img--building{
    margin-top: 10vh;
    display: block;
    position: absolute;
    bottom: 0;
}

@include media-breakpoint-up(md){
    .img{
        position: absolute;
        display: block;
    }

    .img--character-1{
        bottom: -15%;
        left: 5%;
    }
    
    .img--character-2{
        bottom: -8%;
        right: 10%;
    }
}
