/** ============================================================================
 * @components Footer
 * ========================================================================== */
 @include media-breakpoint-up(md){
    .footer--small{
        display: none !important;
    }

    .footer--default{
        display: block !important;
    }
}

.footer{
    background-color: $color-gray-l5;
    ol{
        padding: 0;
        margin: 0;
    }
    li{
        display: inline-block;
        padding: 0 5px;

        img{
            max-width: 100px;
            height: auto;
        }
    }
}   

.col--download{
    text-align: center;
}

.download{
    text-align: right;
    display: inline-block;
    vertical-align: bottom;
    padding-bottom: 10px;
}

.row--partnership{
    padding-top: 20px;
    text-align: center;

    @include media-breakpoint-down(md){
        padding-top: 1vh;
        padding-bottom: 1vh;
    }

    li{
        padding: 5px;
    }
}

/* .btn--backTop{
    background-color: $primary-color;
    color: $secondary-color !important;
    width: 220px;
    height: 50px;
    border-radius: 1em;
    margin-left: 20px;
    margin-top: 5vh;
} */

.row--download{
    padding-top: 5vh;
    vertical-align: middle;
    text-align: justify;

    @include media-breakpoint-down(md){
        padding-top: 0 !important;
    }
}

.row--download div{
    display: inline-block;
    margin: auto;
    margin-left: 0 !important;
    vertical-align: bottom;
    padding: 10px;
}

.row--download p{
    font-size: .8rem;
    text-align: right;
    color: $color-gray-l2;
}

.row--copy{
    text-align: center;
    max-width: 80%;
    margin: auto;
    p{
        font-size: .7rem;
        color: $color-gray-l2;
    }

    h6{
        font-size: .8rem;
        padding-bottom: 3vh;
    }
}

.row--media{
    padding-bottom: 2vh;
}

.row--t-c{
    text-align: center;
    padding-bottom: 10px;
    h6{
        padding: 5px 0;
        font-size: .7rem;
    }
    a{
        display: inline-block;
        color: $color-dark-blue;
        margin-top: 10px;
    }
    span{
        padding: 5px 10px;
    }

    img{
        padding: 2px;
        display: inline-block;
    }
}

//default
.footer--default{
    display: none;
    padding: 30px;
    .row--copy{
        text-align: left;
        margin: 0;
    }

    .row--copy h6{
        font-size: .7rem;
        padding-bottom: 20px;
    }

    .row--copy p{
        font-size: .7rem;
    }

    .row--t-c{
        text-align: left;
        font-size: .6rem !important;
        padding-left: 15px;
        padding-bottom: 0;
    }

    .row--download{
        padding-top: 10px;
    }

    .row--download div{
        margin-right: 0;
        margin-left: 20%;
    }

    .row--partnership{
        padding-top: 10px;
        padding-bottom: 10px;

        img{
            max-width: 7vw;
            height: auto;
        }
    }

    .col--right{
        align-self: flex-end;
    }
}