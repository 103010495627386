html,
body {
    font-family: Roboto, Raleway, Inter, sans-serif;
    color: $color-ebony-black;
    background-color: #F5F5F5;
    position: relative;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: bold;
}

// Reset buttons, inputs appearance
button {
    border: none;
    appearance: none;
}

input {
    border: 0;
    appearance: none;
}

.section-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}